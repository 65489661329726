import axios from "axios";
import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from "buefy";


class ExcelBase {
  constructor(apiUrl) {
    this.filename = null;
    this.fl = null;
    this.apiUrl = apiUrl;
    this.payLoadData = null;
  }

  setFilename(filename) {
    this.filename = filename;
  }

  setApiUrl(apiUrl) {
    this.apiUrl = apiUrl;
  }

  exportFile() {
    this.reset();
    this.writeWorkbook();
  }

  importFile(fl) {
    this.fl = fl;
    const reader = new FileReader();
    reader.onload = event => this.load(event);
    // reader.onerror = () => Toast.open("Format excel tidak sesuai.");
    reader.readAsArrayBuffer(fl);
  }

  writeWorkbook() { }

  load() { }

  loadMessage(msg) { return msg; }

  showErrorMessage(errorMessages) {
    const message = this.loadMessage(errorMessages);
    Dialog.alert({
      title: "Upload Error",
      message: message,
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa",
      ariaRole: "alertdialog",
      ariaModal: true
    });
  }

  getPayload() {
    let data = JSON.parse(JSON.stringify(this.payLoadData));
    return data;
  }

  reset() {
    this.payLoadData = null;
    this.fl = null;
  }

  post(onSaved, onFinished) {
    const data = this.getPayload();
    axios
      .post(this.apiUrl, { data_set: data })
      .then((response) => {
        Toast.open("Data berhasil diupload.");
        this.reset();
        if (onSaved) onSaved(response);
      })
      .catch(error => {
        this.reset();
        if (error.response.status === 400) {
          this.showErrorMessage(error.response.data);
        }
      }).finally(() => { if (onFinished) onFinished(); });
  }

  patch(onSaved) {
    const data = this.getPayload();
    axios
      .patch(this.apiUrl, data)
      .then(response => {
        if (onSaved) onSaved(response);
      })
      .catch(error => {
        this.reset();
        if (error.response.status === 400) {
          this.showErrorMessage(error.response.data);
        } else if (error.response.status == 403) {
          Toast.open({
            message:
              "Pastikan menggunakan excel yang terakhir di download (maksimal 3 hari yang lalu) dan belum pernah digunakan.",
            duration: 5000
          });
        }
      });
  }
}


export default ExcelBase;